<template>
  <div class="my">
    <div class="userInfo">
      <div class="infoLeft">
        <div class="leftTop">
          <el-avatar v-if="userInfo.Icon" :size="60" :src="userInfo.Icon"></el-avatar>
          <el-avatar v-else :size="60" icon="el-icon-user-solid"></el-avatar>
          <span class="userName">{{ userInfo.Name }}</span>
        </div>
        <div class="leftBottom">
          <ul>
            <li style="" :class="menuActive == item.title ? 'active' : ''" v-for="item in menuList" :key="item.title"
              @click="menuClick(item)">
              {{ item.title }}
              <el-badge v-if="item.title == '通知' && UnreadTotal > 0" :value="UnreadTotal" :max="99"
                class="item"></el-badge>
            </li>
          </ul>
        </div>
      </div>
      <div class="infoRight">
        <div class="rightCard" v-show="menuActive == '用户信息'">
          <div class="cardHeader">{{ menuActive }}</div>
          <div class="cardContent">
            <el-form ref="form" :model="form" label-width="100px" label-position="left">
              <div class="formLable" style="
                  padding: 0 0 20px 0;
                  box-shadow: none;
                  align-items: center;
                ">
                <div class="el-form-item__label" style="width: 100px; text-align: left">
                  手机账号
                </div>
                <el-avatar v-if="userIcon" :size="100" :src="userIcon"></el-avatar>
                <el-avatar v-else :size="100" icon="el-icon-user-solid"></el-avatar>
                <el-upload style="margin-left: 20px"
                  :action="$rootUrl + '/Base_Manage/Upload/VipUserImgOssUploadFileByForm'"
                  :headers="{ Authorization: 'Bearer ' + TokenCache.getToken(), }" :on-success="handleSuccess"
                  :before-upload="beforeAvatarUpload" :show-file-list="false" class="myupload" :auto-upload="true"
                  :on-change="handleCrop" accept=".png,.jpg" :data="{ type: 0 }" list-type="picture">
                  <el-button size="mini" type="primary" plain>更换头像
                  </el-button>
                </el-upload>
              </div>
              <el-form-item label="姓名">
                <el-input v-model="userInfo.Name"></el-input>
              </el-form-item>
              <el-form-item label="联系电话">
                <el-input v-model="userInfo.Phone" disabled></el-input>
              </el-form-item>
              <el-form-item label="生日">
                <el-date-picker type="date" placeholder="选择日期" v-model="userInfo.Birth" format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd" style="width: 100%; border-radius: 3px"></el-date-picker>
              </el-form-item>
              <el-form-item label="邮箱">
                <el-input v-model="userInfo.Email"></el-input>
              </el-form-item>
              <el-form-item label="所在地">
                <el-input v-model="userInfo.Address"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button size="mini" type="primary" @click="onSubmitUserInfo" :loading="loading"
                  style="width: 100%">保存
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div v-show="menuActive == '安全设置'">
          <div class="rightCard">
            <div class="cardHeader">{{ menuActive }}</div>
            <div class="cardTip">
              所有登录帐号都可用于登录，主邮箱帐号可收取通知。
            </div>
            <!-- <div class="formLable">
              <div
                class="el-form-item__label"
                style="width: 100px; text-align: left"
              >
                邮箱账号
              </div>
              <el-input
                class="el-form-item__content"
                v-model="userInfo.Email"
                placeholder="输入邮箱地址"
              ></el-input>
              <el-button size="mini" type="primary" style="margin-left: 10px"
                >确认绑定</el-button
              >
            </div> -->
            <div class="formLable">
              <div class="el-form-item__label" style="width: 100px; text-align: right">
                手机认证：
              </div>
              <div class="el-form-item__content" style="align-items: center;width: 20%">
                <el-tag v-if="this.op().Phone" type="success" style="width: 100px">{{ this.op().Phone }}</el-tag>
                <el-tag v-else type="danger" style="width: 100px">&nbsp;</el-tag>
              </div>
              <el-button style="height: 30px; margin-top: 5px" size="mini" type="primary" @click="handlerChangePhone">{{
                this.op().Phone ? '更换手机' : '点击绑定'
              }}
              </el-button>
            </div>

            <div class="formLable">
              <div class="el-form-item__label" style="width: 100px; text-align: right">
                微信认证：
              </div>
              <div class="el-form-item__content" style="align-items: baseline;width: 20%;">
                <img v-if="this.op().UnionId && this.op().UnionId.length > 0"
                  src="@/assets/WeChatSource/wx_circular_green_200.svg" alt="微信认证logo" width="40px" height="40px" />
                <img v-else src="@/assets/WeChatSource/wx_circular_grey_200.svg" alt="微信认证logo" width="40px"
                  height="40px" />
              </div>
              <el-button style="height: 30px; margin-top: 5px" size="mini" type="primary"
                v-if="this.op().UnionId && this.op().UnionId.length > 0" @click="WXDialogbox">更换微信
              </el-button>
              <el-button v-else style="height: 30px; margin-top: 5px" size="mini" type="primary"
                @click="WXDialogbox">点击认证
              </el-button>
            </div>
          </div>
          <div style="margin-top: 10px" class="rightCard">
            <div class="cardHeader">登录密码</div>
            <div class="cardContent">
              <el-form ref="form" :model="form" label-width="100px" label-position="right">
                <el-form-item label="旧密码：">
                  <el-input v-model="form.oldPwd"></el-input>
                </el-form-item>
                <el-form-item label="新密码：">
                  <el-input v-model="form.newPwd" show-password></el-input>
                </el-form-item>
                <el-form-item label="确认新密码：">
                  <el-input v-model="form.confirmPwd" show-password></el-input>
                </el-form-item>
                <el-form-item class="lastBtn">
                  <el-button :loading="loading" size="mini" type="primary" style="width: 120px" @click="onSubmit">保存
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
          <div style="margin-top: 10px" class="rightCard">
            <div class="cardHeader">注销账号</div>
            <div class="cardTip">
              注销后，你将不能再登录此帐号、邮箱、手机、第三方帐号均将被解绑。<br />
              <el-button size="mini" type="danger" style="margin-top: 20px" @click="cancellation">注销账号
              </el-button>
            </div>
          </div>
        </div>
        <div class="rightCard" v-show="menuActive == '通知'">
          <div class="cardHeader">{{ menuActive }}</div>
          <el-empty v-if="data.length == 0" description="暂无通知"></el-empty>
          <div class="content" v-else>
            <el-row v-for="item in data" :key="item.Id" @click.native="read(item)" class="box">
              <el-col :span="20">
                <div>{{ item.Remarks }}</div>
                <span style="color: #9b9999;margin-top: 5px;display: block;">{{ timeDataFormat(item.CreateTime,
                  'YYYY-MM-DD HH:mm:ss') }}</span>
              </el-col>
              <el-col :span="4" class="tagbox">
                <el-tag v-if="item.Status == 0" type="danger">未读</el-tag>
                <el-tag v-else type="success">已读</el-tag>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <!-- 微信绑定弹框 -->
    <el-dialog style="margin-top: -10vh;font-weight: bold;font-size: 36px;" title="请使用微信扫码，按提示进行微信账号授权验证"
      :visible.sync="WXDialog" width="30%" center>
      <div class="mt-12 flex flex-col items-center">
        <div style="width: 100%">
          <div v-loading="loading" class="loginAndRegister">
            <div style="display: flex; justify-content: center">
              <wxlogin id="login_container" :appid="appid" :redirect_uri="getRedirect_uri()" :scope="scope" href=""
                state="STATE" style=""></wxlogin>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 修改绑定手机号弹窗 -->
    <el-dialog title="换绑手机" :visible.sync="centerDialogVisible" width="20%" center>
      <div class="register_box_right">
        <div class="content">
          <el-form :model="Form" ref="Form" class="regform" label-width="0">
            <div class="inputs phone" style="">
              <img style="width: 11%" src="../../assets/phone.png" alt="" />
              <el-form-item prop="tel" class="input">
                <el-input type="text" v-model.number="Form.newPhone" placeholder="手机号码">
                </el-input>
              </el-form-item>
            </div>
            <div class="inputs input3" style="">
              <img style="flex: 1" src="../../assets/code.png" alt="" />
              <el-form-item prop="verification" class="input1" style="flex: 6">
                <el-input type="text" v-model="Form.code" placeholder="输入验证码"
                  oninput="value=value.replace(/[^\d]/g,'')">
                </el-input>
              </el-form-item>
              <button class="button_obtain" v-show="sendAuthCode" @click.prevent="codeChange" style="outline: none">
                获取验证码
              </button>
              <span v-show="!sendAuthCode" class="auth_text">
                <span class="auth_text_blue">{{ auth_time }}</span>秒之后重发</span>
            </div>
            <el-form-item>
              <el-button type="success" class="register_button" round @click.native.prevent="handlerChangePhoneSubmit"
                :loading="logining">
                换绑
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
    <cropperImage :dialogVisible="showCropper" :cropper-option="cropperOption" :cropper-style="cropperStyle"
      @close="showCropper = false" @uploadCropper="uploadImg"></cropperImage>

  </div>
</template>

<script>
const reg = /^1[345789]\d{9}$/; // 手机号正则
import TokenCache from "@/utils/cache/TokenCache";
import { mapState, mapActions } from "vuex";
import wxlogin from "vue-wxlogin";
import cropperImage from '@/components/cropperImage/index.vue'


export default {
  data() {
    return {
      appid: "wxef625270eda5492e",
      scope: "snsapi_login",
      WXDialog: false,
      // 修改手机号弹窗
      centerDialogVisible: false,
      data: [],
      logining: false,
      sendAuthCode: true, //显示‘获取按钮'还是'倒计时'
      auth_time: 0 /*倒计时 计数器*/,
      verification: "", //绑定输入验证码框
      menuList: [
        {
          title: "用户信息",
        },
        {
          title: "安全设置",
        },
        {
          title: "通知",
        },
      ],
      menuActive: "用户信息",
      form: {},
      // 修改绑定手机号表单
      Form: {},
      loading: false,
      total: 0,
      UnreadTotal: "",
      TokenCache,
      userIcon: "",
      // 上传图片剪裁
      fileSrcList: [],
      showCropper: false, // 显示裁剪弹窗
      cropperOption: { // 裁剪配置
        img: '',
        autoCropWidth: 300,
        autoCropHeight: 300
      },
      cropperStyle: { // 裁剪框大小
        width: '500px',
        height: '500px'
      },
      cropperData: {}

    };
  },
  components: { wxlogin, cropperImage },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    // 清空修改手机号表单数据
    this.Form = {};
    document.title = "筑赢台 - 个人账号中心";
    this.getDataList();
    this.userIcon = this.userInfo.Icon;
    this.menuActive = this.$route.query.type
      ? this.$route.query.type
      : "用户信息";
  },
  methods: {
    ...mapActions(["getUserInfo"]),
    // 获取用户信息
    op() {
      return this.$store.state.userInfo
    },
    // 发送验证码
    codeChange() {
      if (!this.Form.newPhone) return this.$message.error("请输入手机号");
      if (!reg.test(this.Form.newPhone))
        return this.$message.error("请核对手机号格式");
      this.$http.post('/Base_Manage/Base_SMSConfig/GetTemplateCodeByCode?code=' + 'ZYT_VIPUser_ReplacePhone',).then(codeData => {
        if (codeData.Success) {
          this.$http
            .post(
              "Base_Manage/Home/GetShortMessage?Phone=" +
              this.Form.newPhone +
              "&TemplateCode=" + codeData.Data
            )
            .then((res) => {
              if (res.Success) {
                this.$message.success("短信发送成功");
                // 显示正在发送
                this.sendAuthCode = false;
                //设置倒计时秒
                this.auth_time = 60;
                var auth_timetimer = setInterval(() => {
                  this.auth_time--;
                  if (this.auth_time <= 0) {
                    this.sendAuthCode = true;
                    clearInterval(auth_timetimer);
                  }
                }, 1000);
              } else {
                this.$message.error(res.Msg);
              }
            });
        } else {
          this.$message.error(codeData.Msg);
        }
      })

    },

    // 注销账号
    cancellation(e) {
      if (this.op().Id === 'testvipuser') {
        this.$message.error("当前测试账号不可注销!");
        return
      }
      let title = `注销后，你将不能再登录此帐号、邮箱、手机、第三方帐号均将被解绑。您确认要注销账号吗? `;
      let isForceLogOut = false;
      if (typeof e == "string") {
        title = e + "或点击确定强制注销";
        isForceLogOut = true;
      }
      this.$confirm(title, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post(
              "Base_Manage/Base_VIPUserInfo/UserAccountCancellation?isForceLogOut=" +
              isForceLogOut
            )
            .then((res) => {
              if (res.Success) {
                localStorage.removeItem("jwtToken");
                localStorage.removeItem("userInfo");
                location.reload();
              } else {
                // this.$api.msg(res.Msg)
                this.cancellation(res.Msg);
              }
            });
        })
        .catch(() => {
        });
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === 'image/jpg' || file.type === "image/png";
      const isLt20M = file.size / 1024 / 1024 < 20;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 或者 PNG 格式!");
      }
      if (!isLt20M) {
        this.$message.error("上传头像图片大小不能超过 20MB!");
      }
      // 校验通过后显示裁剪框
      this.canCropper = isJPG && isLt20M
      return false
    },
    handleSuccess(res, file) {
      if (res.Code == 200) {
        this.userIcon = res.Url;
      }
    },
    menuClick(item) {
      this.menuActive = item.title;
    },
    onSubmitUserInfo() {
      this.loading = false;
      this.userInfo.Icon = this.userIcon;
      this.$http
        .post("/Base_Manage/Base_VIPUserInfo/SaveData", this.userInfo)
        .then(async (res) => {
          this.loading = false;
          if (res.Success) {
            await this.getUserInfo();
            this.$message.success("操作成功");
          } else this.$message.error(res.Msg);
        });
    },
    WXDialogbox() {
      this.WXDialog = true;
    },
    // 更换绑定手机号
    handlerChangePhone() {
      this.centerDialogVisible = true;
    },
    // 换绑手机提交
    handlerChangePhoneSubmit() {
      if (!this.Form.newPhone) return this.$message.error("请输入手机号");
      if (!reg.test(this.Form.newPhone))
        return this.$message.error("请核对手机号格式");
      if (!this.Form.code) return this.$message.error("请输入验证码");
      console.log("更换绑定手机号", this.Form);
      // 关闭弹窗
      this.centerDialogVisible = false;
      let oldPhone = JSON.parse(localStorage.getItem("userInfo")).Phone;
      this.$http
        .post("Base_Manage/Home/UserReplacePhone", {
          Phone: oldPhone,
          SmsCode: this.Form.code,
          NewPhone: this.Form.newPhone,
        })
        .then((res) => {
          if (res.Success) {
            this.$message.success("手机号变更成功,请重新登录");
            setTimeout(() => {
              TokenCache.deleteToken();
              this.$store.state.userInfo = {};

              this.$store.state.isLogin = false;
              this.$router.replace("/Home");
            }, 1000);
          } else {
            this.$message.error("res.Msg");
          }
        });
    },

    // 修改密码
    onSubmit() {
      var that = this;
      console.log("打印form", this.form);
      if (!this.form.oldPwd) return this.$message.error("请输入旧密码");
      if (this.form.oldPwd === this.form.newPwd)
        return this.$message.error("新密码不能与旧密码相同");
      if (!this.form.newPwd) return this.$message.error("请输入新密码");
      if (!this.form.confirmPwd) return this.$message.error("请再次输入新密码");
      if (this.form.newPwd !== this.form.confirmPwd)
        return this.$message.error("两次输入的密码不一致");
      console.log("可以走了");
      this.loading = true;
      this.$http
        .post("/Base_Manage/Home/VIPUserChangePwd", {
          oldPwd: this.form.oldPwd,
          newPwd: this.form.newPwd,
        })
        .then((res) => {
          this.loading = false;
          if (res.Success) {
            this.$message.success("修改成功");
            setTimeout(function () {
              that.$message.warning("请使用新密码重新登录");
              TokenCache.deleteToken();
              that.$store.state.userInfo = {};
              that.$store.state.isLogin = false;
              that.$router.replace("/Home");
            }, 1000);
          } else this.$message.error(res.Msg);
        });
    },
    // 回调地址获取
    getRedirect_uri() {
      let protocolStr = document.location.protocol;
      return encodeURIComponent(protocolStr + "//www.zytsaas.com/My/BindingWX");
    },
    // 获取当前用户通知消息
    getDataList() {
      this.$http
        .post("Base_Message/Base_InviteMessage/GetInviteMessageList", {
          SortField: "CreateTime",
          SortType: "desc",
          PageIndex: 1,
        })
        .then((res) => {
          if (res.Success) {
            this.data = res.Data;
            this.total = res.Total;
            this.UnreadTotal = res.UnreadTotal;
          }
        });
    },
    read(item) {
      if (item.Status != 0) return;
      this.$http
        .post(
          "/Base_Message/Base_InviteMessage/UpdateInviteMessageData?Id=" +
          item.Id
        )
        .then((res) => {
          if (res.Success) {
            item.Status = 1;
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.getDataList();
          } else
            this.$message({
              type: "error",
              message: res.Msg,
            });
        });
    },
    // 上传图片剪裁
    handleCrop(file) {
      // 点击弹出剪裁框
      this.$nextTick(() => {
        if (this.canCropper) {
          this.cropperOption.img = window.URL.createObjectURL(file.raw)
          this.showCropper = this.canCropper
        }
      })
    },
    // 自定义上传方法
    async uploadImg(file, data) {
      let fileFormData = new FormData()
      fileFormData.append('file', file);
      fileFormData.append('type', 0);
      // 移除上传组件带来的bug
      // document.getElementsByTagName('body')[0].removeAttribute('style')
      this.cropperData.iconUrl = data
      this.showCropper = false
      //调用上传文件接口 以及回调
      const res = await this.$http.post('Base_Manage/Upload/VipUserImgOssUploadFileByForm', fileFormData);
      if (res.Code != 200) {
        this.$message.error(res.msg);
        return
      }
      this.userIcon = res.Url;
      this.onSubmitUserInfo();
    },

  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input--prefix .el-input__inner {
  border-radius: 5px;
}

::v-deep .el-avatar img {
  display: block;
  height: 100%;
  width: 100%;
  vertical-align: middle;
}

.loginAndRegister {
  width: 100%;
  // min-height: 63vh;
  margin: 0 auto;

  .box1,
  .box2 {
    background-color: #ffffff;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 0 30px;
  }

  .box1 .el-form-item {
    margin-top: 20px;
  }

  .box1 .btn1 {
    font-size: 20px;
  }

  .box1 .el-form-item .imgs1 {
    width: 37px;
    margin-top: 11px;
    margin-left: -55%;
  }

  .box1 .Back {
    margin-left: 75%;
    color: #d1100b;
    font-size: 20px;
    display: flex;
    margin-top: -14%;
    cursor: pointer;
  }

  .box1 .revise {
    margin-left: 79%;
    color: rgb(95, 170, 255);
    font-size: 20px;
    display: flex;
    margin-top: -11.5%;
    cursor: pointer;
  }
}

.reuse {
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 5px 0 rgb(57 66 60 / 20%);
  box-sizing: border-box;
}

.active {
  font-weight: bold;
  color: #000 !important;
}

.lastBtn {
  margin-bottom: 0;
}

::v-deep .el-form-item__label {
  font-size: 14px;
  color: gray;
  font-weight: bold;
}

::v-deep .infoRight {
  .el-form-item__content {
    width: 300px;
    height: 40px;
  }
}

.my {
  width: 100%;
  height: 100%;
  background: #ededed;
  overflow-y: auto;

  .register_box_right {
    .content {

      // width: 8.592593rem;
      // height: 70%;
      // margin: 30vh auto;
      .inputs {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          margin-bottom: 21px;
        }
      }

      .auth_text {
        margin: -22px 0 0 10px;
      }

      .inputs.phone {
        .el-form-item {
          width: 100% !important;
        }
      }

      .content_text {
        font-size: 0.518519rem;
        font-weight: 600;
        color: #525252;
        line-height: 0.611111rem;
        margin-bottom: 0.8rem;
      }

      .button_obtain {
        cursor: pointer;
        margin: -22px 0 0 5px;
      }

      button {
        height: 37px;
        width: 25%;
        border: none;
        color: #ffffff;
        background: linear-gradient(223deg, #f74340 0%, #fc8e8b 100%);
        border-radius: 0.074074rem 0.074074rem 0.074074rem 0.074074rem;
        opacity: 1;
      }

      .register_button {
        width: 100%;
        border-radius: 0.111111rem 0.111111rem 0.111111rem 0.111111rem;
        opacity: 1;

        button {
          background: linear-gradient(223deg, #f74340 0%, #fc8e8b 100%);
          width: 100%;
          height: 100%;
          border: none;
          font-size: 0.296296rem;
          font-weight: 500;
          color: #ffffff;
          line-height: 0.351852rem;
        }
      }
    }
  }

  .navHeader {
    @extend .reuse;
    border-radius: 0;
    padding: 0 10px;
    height: 48px;
    line-height: 48px;
    display: flex;
    justify-content: space-between;
    color: #000;

    .logoLeft {
      font-weight: bold;
    }

    .userRight {
      display: flex;
      align-items: center;
      width: 90px;
      justify-content: space-between;
      padding: 0 20px;
      border-left: 1px solid rgba(0, 0, 0, 0.07);
      cursor: pointer;
    }
  }

  ::v-deep .el-dialog__wrapper {
    .el-dialog {
      margin-top: 30vh !important;
    }
  }

  .userInfo {
    width: 1000px;
    margin: 0 auto;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 0 20px;
    padding-bottom: 20px;
    display: flex;

    .infoLeft {
      box-sizing: border-box;
      width: 320px;
      padding: 0 10px;

      .leftTop {
        @extend .reuse;
        height: 100px;
        padding: 20px;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;

        .userName {
          margin-left: 20px;
        }
      }

      .leftBottom {
        @extend .reuse;
        margin-top: 10px;

        ul {
          cursor: pointer;

          li {
            padding: 15px 20px;
            font-size: 18px;
            color: grey;
            box-shadow: 0 1px 0 rgb(0 0 0 / 5%);
            display: flex;
            justify-content: space-between;

            &:hover {
              background: #f1f1f1;
              font-weight: bold;
            }
          }
        }
      }
    }

    .infoRight {
      flex: 1;
      padding: 0 10px;

      .rightCard {
        @extend .reuse;

        .content {
          height: 85vh;
          overflow-y: auto;
          padding: 15px;
          margin-top: 20px;
        }

        .content .box {
          border: 1px solid #d3d3d3;
          border-radius: 10px;
          padding: 10px;
          margin-top: 10px;
          display: flex;
          align-items: center;

          .tagbox {
            text-align: right;
          }
        }

        .cardHeader {
          padding: 20px 20px 0;
          font-size: 18px !important;
          @extend .active;
        }

        .cardTip {
          color: gray;
          padding: 20px;
          padding-top: 6px;
          font-size: 14px;
          margin: 0;
          box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
        }

        .formLable {
          display: flex;
          padding: 20px;
          // box-shadow: 0 1px 0 rgb(0 0 0 / 10%);
        }

        .cardContent {
          padding: 20px;
        }
      }
    }
  }
}
</style>
